@use '..' as able;

.eg-surface {
  border-radius: 3px;
  margin: 20px auto 1rem;
  max-width: 240px;
  min-height: 180px;
  padding: 20px;

  svg {
    display: block;
    margin: 20px auto 0;
  }

  a {
    color: currentColor;
    text-decoration: none;
    text-shadow: none;
  }

  &_label {
    @include able.TextStyle(FinePrint, Center);
    display: block;
    padding-top: 20px;
  }
}

@each $name in able.$Surface-aliases {
  .able-Surface--#{$name} {
    @include able.Surface($name);
  }

  .able-Surface--#{$name}--interactive {
    @if $name != SurfaceTop {
      @include able.Surface($name, true);
    }
  }
}
