@use ".." as able;

.able-Lozenge--Neutral {
  @include able.Lozenge(Neutral);
}

.able-Lozenge--NeutralEmphasis {
  @include able.Lozenge(NeutralEmphasis);
}

.able-Lozenge--Positive {
  @include able.Lozenge(Positive);
}

.able-Lozenge--PositiveEmphasis {
  @include able.Lozenge(PositiveEmphasis);
}

.able-Lozenge--Negative {
  @include able.Lozenge(Negative);
}

.able-Lozenge--NegativeEmphasis {
  @include able.Lozenge(NegativeEmphasis);
}

.able-Lozenge--Featured {
  @include able.Lozenge(Featured);
}

.able-Lozenge--Tier {
  @include able.Lozenge(Tier);
}

.able-Lozenge--TierEmphasis {
  @include able.Lozenge(TierEmphasis);
}

.able-Lozenge--TPlusGold {
  @include able.Lozenge(TPlusGold);
}
