@use '..' as able;

.able-ActionButton--low-emphasis {
  @include able.ActionButton('LowEmphasis');
}

.able-ActionButton--low-emphasis-big {
  @include able.ActionButton('LowEmphasisBig');
}

.able-ActionButton--destructive {
  @include able.ActionButton('LowEmphasisDestructive');
}

.able-ActionButton--medium-emphasis {
  @include able.ActionButton('MediumEmphasis');
}

.able-ActionButton--high-emphasis {
  @include able.ActionButton('HighEmphasis');
}

.able-ActionButton--high-emphasis-destructive {
  @include able.ActionButton('HighEmphasisDestructive');
}
