@use '..' as able;

.able-IconButton {
  @include able.IconButton();

  &--Default {
    @include able.IconButton();
  }

  &--Big {
    @include able.IconButton('Big');
  }

  &--Destructive {
    @include able.IconButton('Destructive');
  }

  &--Big--Destructive {
    @include able.IconButton('BigDestructive');
  }
}
