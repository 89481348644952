@use ".." as able;

.able-MessageInline {
  &--Attention {
    @include able.MessageInline(Attention);
  }
  &--Error {
    @include able.MessageInline(Error);
  }
  &--Information {
    @include able.MessageInline(Information);
  }
  &--Success {
    @include able.MessageInline(Success);
  }
  &--Warning {
    @include able.MessageInline(Warning);
  }
}
